export default [
  {
    meta: {
      title: 'Pengajuan Proposal',
      auth: true,
      authorization: ['admin']
    },
    path: '/pengajuan_proposal',
    name: 'pengajuan_proposal',
    component: () => import('../views/Proposal.vue')
  },
  {
    meta: {
      title: 'Edit Proposal',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/proposal/:id',
    name: 'proposal.edit',
    component: () => import('../views/EditProposal.vue'),
    props: true
  }
]
