export default [
  {
    meta: {
      title: 'Data Mahasiswa',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/mahasiswa',
    name: 'mahasiswa',
    component: () => import('../views/mahasiswa/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Mahasiswa',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/mahasiswa/create',
    name: 'mahasiswa.create',
    component: () => import('../views/mahasiswa/Form.vue')
  },
  {
    meta: {
      title: 'Edit Mahasiswa',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/mahasiswa/:id',
    name: 'mahasiswa.edit',
    component: () => import('../views/mahasiswa/Form.vue'),
    props: true
  },
  {
    meta: {
      title: 'Show Mahasiswa',
      auth: true,
      authorization: ['admin', 'dosen']
    },
    path: '/mahasiswa/show/:id',
    name: 'mahasiswa.show',
    component: () => import('../views/mahasiswa/Show.vue'),
    props: true
  }
]
