export default [
  {
    meta: {
      title: 'Edit Informasi',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/edit_informasi/:id',
    name: 'edit_informasi.edit',
    component: () => import('../views/SettingAkademik.vue'),
    props: true
  }
]
