export default [
  {
    meta: {
      title: 'Tambah Tahun',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/tahun',
    name: 'tahun',
    component: () => import('../views/tahun/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Tahun',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/tahun/create',
    name: 'tahun.create',
    component: () => import('../views/tahun/Form.vue')
  },
  {
    meta: {
      title: 'Edit Tahun',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/tahun/:id',
    name: 'tahun.edit',
    component: () => import('../views/tahun/Form.vue'),
    props: true
  }
]
