export default [
  {
    meta: {
      title: 'Tambah Kelas',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/kelas',
    name: 'kelas',
    component: () => import('../views/kelas/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Kelas',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/kelas/create',
    name: 'kelas.create',
    component: () => import('../views/kelas/Form.vue')
  },
  {
    meta: {
      title: 'Edit Kelas',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/kelas/:id',
    name: 'kelas.edit',
    component: () => import('../views/kelas/Form.vue'),
    props: true
  }
]
