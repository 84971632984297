export default [
  {
    meta: {
      title: 'Setting Proposal',
      auth: true,
      authorization: ['admin']
    },
    path: '/setting_proposal',
    name: 'setting_proposal',
    component: () => import('../views/SettingProposal.vue')
  },
  {
    meta: {
      title: 'Tambah Proposal',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/setting_proposal/create',
    name: 'proposal.create',
    component: () => import('../views/FormAddSetting.vue'),
    props: true
  },
  {
    meta: {
      title: 'Edit Proposal',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/setting_proposal/:id',
    name: 'setting_proposal.edit',
    component: () => import('../views/FormAddSetting.vue'),
    props: true
  },
  {
    meta: {
      title: 'Setting Akademik',
      auth: true,
      authorization: ['admin']
    },
    path: '/setting_akademik_edit',
    name: 'setting_akademik_edit',
    component: () => import('../views/SettingAkademik.vue')
  }
]
