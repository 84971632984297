export default [
  {
    meta: {
      title: 'Pengajuan Sidang',
      auth: true,
      authorization: ['admin']
    },
    path: '/pengajuan_sidang',
    name: 'pengajuan_sidang',
    component: () => import('../views/sidang/Sidang.vue')
  },
  {
    meta: {
      title: 'Edit Sidang',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/sidang/:id',
    name: 'sidang.edit',
    component: () => import('../views/sidang/EditSidang.vue'),
    props: true
  }
]
