export default [
  {
    meta: {
      title: 'Pengajuan Bukti Bayar',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/pengajuan_bukti_bayar',
    name: 'pengajuan_bayar',
    component: () => import('../views/Pengajuan.vue')
  }, {
    meta: {
      title: 'Evaluasi Bukti Bayar',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/evaluasi_bayar/:id',
    name: 'evaluasi_bayar',
    component: () => import('../views/EvaluasiBayar.vue')
  },
  {
    meta: {
      title: 'Pengajuan Proposal',
      auth: true,
      authorization: ['mahasiswa']
    },
    path: '/pengajuan_mahasiswa',
    name: 'pengajuan_mahasiswa',
    component: () => import('../views/pengajuan/Pengajuan.vue')
  },
  {
    meta: {
      title: 'Pengajuan Sidang',
      auth: true,
      authorization: ['mahasiswa']
    },
    path: '/pengajuan_sidang_mahasiswa',
    name: 'pengajuan_sidang_mahasiswa',
    component: () => import('../views/pengajuan/PengajuanSidang.vue')
  },
  {
    meta: {
      title: 'Data Dosen',
      auth: true,
      authorization: ['mahasiswa']
    },
    path: '/pembimbing',
    name: 'pembimbing',
    component: () => import('../views/pengajuan/Pembimbing.vue')
  },
  {
    meta: {
      title: 'Edit Proposal',
      auth: true,
      authorization: ['admin']
    },
    path: '/pengajuan_proposal/:id',
    name: 'pengajuan_proposal.edit',
    component: () => import('../views/EditProposal.vue')
  },
  {
    meta: {
      title: 'Evaluasi Proposal',
      auth: true,
      authorization: ['dosen']
    },
    path: '/evaluasi_proposal/:id',
    name: 'evaluasi_proposal',
    component: () => import('../views/EvaluasiProposal.vue')
  }

]
