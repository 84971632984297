export default [
  {
    meta: {
      title: 'Tambah Jenjang',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/jenjang',
    name: 'jenjang',
    component: () => import('../views/jenjang/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Jenjang',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/jenjang/create',
    name: 'jenjang.create',
    component: () => import('../views/jenjang/Form.vue')
  },
  {
    meta: {
      title: 'Edit Jenjang',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/jenjang/:id',
    name: 'jenjang.edit',
    component: () => import('../views/jenjang/Form.vue'),
    props: true
  }
]
