import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mahasiswa from './mahasiswa'
import dosen from './dosen'
import thesis from './thesis'
import user from './user'
import pengajuan from './pengajuan'
import proposal from './proposal'
import settingproposal from './settingproposal'
import daftarsidang from './daftarsidang'
import informasi from './informasi'
import konsentrasi from './konsentrasi'
import kelas from './kelas'
import semester from './semester'
import tahun from './tahun'
import jenjang from './jenjang'
import history from './history'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Dashboard',
      auth: true,
      authorization: ['admin', 'adminkbk', 'dosen', 'mahasiswa']
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Dashboard',
      auth: true,
      authorization: ['mahasiswa']
    },
    path: '/home',
    name: 'home_mahasiswa',
    component: () => import('../views/MahasiswaDashboard.vue')
  },
  {
    meta: {
      title: 'Dashboard',
      auth: true,
      authorization: ['dosen']
    },
    path: '/home_dosen',
    name: 'home_dosen',
    component: () => import('../views/DosenDashboard.vue')
  },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    meta: {
      title: 'Register'
    },
    path: '/register',
    name: 'auth.register',
    component: () => import('../views/auth/Register.vue')
  },
  {
    meta: {
      title: 'Forgot Password'
    },
    path: '/forgot',
    name: 'auth.forgot',
    component: () => import('../views/auth/ForgotPassword.vue')
  },
  {
    meta: {
      title: 'Profile',
      auth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import('../views/pengaturan/Profile.vue')
  },
  ...mahasiswa,
  ...dosen,
  ...thesis,
  ...user,
  ...pengajuan,
  ...proposal,
  ...settingproposal,
  ...daftarsidang,
  ...informasi,
  ...konsentrasi,
  ...kelas,
  ...semester,
  ...tahun,
  ...jenjang,
  ...history
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }

  // handle authorization
  if (to.matched.some(record => record.meta.authorization)) {
    const authorization = to.meta.authorization
    const userInfo = localStorage.getItem('user')
    // console.log(userInfo)
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      const currentRole = userData.user.roles[0].name

      const cek = authorization.indexOf(currentRole) >= 0
      if (!cek) {
        next('/')
      }

      // console.log(authorization)
      // console.log(currentRole)
      // console.log(cek)
    }
  }

  next()
})

export default router
