export default [
  {
    meta: {
      title: 'Tambah Konsentrasi',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/konsentrasi',
    name: 'konsentrasi',
    component: () => import('../views/konsentrasi/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Konsentrasi',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/konsentrasi/create',
    name: 'konsentrasi.create',
    component: () => import('../views/konsentrasi/Form.vue')
  },
  {
    meta: {
      title: 'Edit Konsentrasi',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/konsentrasi/:id',
    name: 'konsentrasi.edit',
    component: () => import('../views/konsentrasi/Form.vue'),
    props: true
  }
]
