export default [
  {
    meta: {
      title: 'History',
      auth: true,
      authorization: ['dosen']
    },
    path: '/history',
    name: 'history',
    component: () => import('../views/history/Index.vue')
  }
]
