<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>

    <div style="font-size: 15px; background: white; margin-top: 20px; display: flex">
      <b-field label="Pilih Periode" horizontal style="flex: 1; margin: 1rem;">
        <v-select style="width: 15%; position: absolute;" v-model="form.akademik_id" label="name" :options="akademik" :reduce="x => x.id"></v-select>
      </b-field>
      <b-button style="margin: 1rem" type="is-success" v-on:click="download()">Download</b-button>
    </div>

    <section class="section is-main-section">
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="account"
          :number="count.mahasiswa"
          label="Mahasiswa"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="cash"
          :number="count.payment"
          label="Pengajuan Bukti Bayar"
        />
        <card-widget
          class="tile is-child"
          type="is-success"
          icon="file-clock"
          :number="count.proposal"
          label="Pengajuan Proposal"
        />
      </tiles>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      akademik: [],
      form: {},
      count: {}
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    currentRole () {
      console.log(this.$store.getters.currentRole)
      return this.$store.getters.currentRole
    }
  },
  mounted () {
    axios
      .get('/akademik')
      .then((r) => {
        this.akademik = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    this.getDetail(0)
  },
  watch: {
    form: function (val) {
      this.getDetail(val.akademik_id)
    }
  },
  methods: {
    getDetail (id) {
      const app = this

      axios
        .get('/akademik/' + id)
        .then((r) => {
          app.count = r.data.count
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    arrayToCSV (data) {
      if (!data || !data.length) {
        return false
      }

      let csvContent = 'data:text/csv;charset=utf-8,'

      csvContent += '"'
      csvContent += Object.keys(data[0]).join('";"')
      csvContent += '"\r\n'

      data.forEach(function (x) {
        const row = Object.values(x).join('";"')
        csvContent += '"'
        csvContent += row.replace(/\r/g, ' ').replace(/\n/g, ' ')
        csvContent += '"\r\n'
      })

      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'data.csv')
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    },
    download () {
      const app = this

      if (!this.form.akademik_id) {
        this.$buefy.toast.open({
          message: 'Error: Pilih Periode Akademik',
          type: 'is-danger'
        })
      }

      axios
        .get('/akademik/download-proposal/' + this.form.akademik_id)
        .then((r) => {
          const d = r.data.data

          if (d.proposals) {
            app.arrayToCSV(d.proposals)
          }
          if (d.mahasiswa) {
            app.arrayToCSV(d.mahasiswa)
          }
          if (d.payments) {
            app.arrayToCSV(d.payments)
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
