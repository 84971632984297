export default [
  {
    meta: {
      title: 'Tambah Semester Akademik',
      auth: true,
      authorization: ['admin', 'keuangan']
    },
    path: '/semester',
    name: 'semester',
    component: () => import('../views/semester/Index.vue')
  },
  {
    meta: {
      title: 'Tambah Semester',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/semester/create',
    name: 'semester.create',
    component: () => import('../views/semester/Form.vue')
  },
  {
    meta: {
      title: 'Edit Semester',
      auth: true,
      authorization: ['admin', 'adminkbk']
    },
    path: '/semester/:id',
    name: 'semester.edit',
    component: () => import('../views/semester/Form.vue'),
    props: true
  }
]
